
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { IComObj } from '@/global/types'
interface IState {
  curIndex: number
  title: string
  text: string
  handleClick: (index: number) => void
}
export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const IData = ref<IComObj>([])
    IData.value = props.data
    const state: IState = reactive({
      curIndex: 0,
      title: '',
      text: '',
      handleClick: (index) => {
        state.curIndex = index
        state.title = props.data[index].name
        state.text = props.data[index].content
      }
    })
    state.handleClick(0)
    return {
      ...toRefs(state),
      IData
    }
  }
})
