
import { defineComponent, ref } from 'vue'
import { IComObj } from '@/global/types'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js'
import { EffectFade, Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
export default defineComponent({
  name: '',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const IData = ref<IComObj>([])
    IData.value = props.data
    return {
      IData,
      modules: [EffectFade, Autoplay, Pagination]
    }
  }
})
