
import { defineComponent, ref } from 'vue'
import { IComObj } from '@/global/types'
export default defineComponent({
  name: '',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props) {
    const IData = ref<IComObj>([])
    IData.value = props.data
    return { IData }
  }
})
