export const allData = {
  views: [
    { name: '电子亮证' },
    { name: '类案推荐' },
    { name: '入企扫码' },
    { name: '全息画像' },
    { name: '预警监控' },
    { name: '证照识别' }
  ],
  intro: [
    {
      name:'勤务巡查',
      content:'实现勤务计划制定、上报、审核、汇总，并对执法人员、车辆、对象进行定位显示，对周边资源进行调派。做到勤务安排更加合理、勤务调派更加便捷、勤务督察更加智能'
    },
    {
      name:'案件办理',
      content:'实现线索的登记、下发、分派、核查，以及行政检查、处罚、强制等相关案件全流程在线办理、全过程在线记录，支持重大案件法制审核、非现场执法，办案过程中提供智能预警、类案推荐、智能文书生成等智能办案助手，案件办理后支持一键组卷归档、一键脱敏公示、一键数据上报'
    },
    {
      name:'指挥调度',
      content:'接入视频、执法记录仪、移动终端，多端实时信息联动和融合通信，一张图全面感知执法资源、执法案件、执法对象分布情况，掌握执法活动轨迹，为执法部门提供针对应急事件精准调度周边执法力量的能力'
    },
    {
      name:'移动执法',
      content:'实现线索管理、行政检查、行政处罚的移动端办理，支持任务接收、案件办理、文书编辑、电子签章、电子送达等指尖处理，提供电子亮证、入企扫码、法规查询、在线沟通、证据固化、企业画像、语音转写等工具，规范办案流程，辅助便捷执法，为基层办案人员减负增效'
    },
    {
      name:'执法队伍',
      content:'实现执法队伍的动态管理，落实行政执法责任制，提升严格规范公正文明的执法水平，帮助执法单位合理配备行政执法力量，加强执法队伍建设'
    },
    {
      name:'执法事项',
      content:'实现执法事项、自由裁量、执法依据动态管理，规范行使行政职权。通过执法事项规范管理提升行政执法的质量和效能，达到法制统一、程序公正、公平合理、高效便民的目的'
    },
    {
      name:'统计分析',
      content:'实现执法人员可通过检索日期、执法人员信息、当事人信息、执法事项、案件信息共5个维度、27个信息项的任意组合对案件进行全面查询。针对执法人员、执法事项、执法对象、执法行为的数量、分布情况、案件结果等指标信息进行统计，并对数据的频数、集中度、分布情况等进行可视化展示'
    },
    {
      name:'系统配置',
      content:'为提高执法案件办理的规范性对文书及流程进行了统一配置，并支持文书及流程环节定制功能，可根据地方业务单位相关案件情况对环节及文书进行个性化定制，在规范办案的同时也提高了整体案件办理系统的灵活性，可以横向和纵向的满足各领域、各区域的行政执法办案需求，打造适用于各执法领域的行政执法办案流程'
    },
  ],
  features: [
    {
      name: '贯彻落实三项制度',
      content:
        '围绕行政执法业务，实现行政执法案件全流程跟踪、重大案件法制审核、执法信息网上公示，实现阳光透明、智能高效、严格公正的执法方式'
    },
    {
      name: '贴合三年行动计划',
      content:
        '建立完善的行政执法业务办案系统，通过执法队伍维护、执法人员考核、执法事项管理，加强人员素质，规范执法行为，全面提升行政执法质量，推进严格规范公正文明执法'
    },
    {
      name: '推动优化营商环境',
      content:
        '通过入企扫码功能，规范执法人员行为，推动执法单位牢固树立执法为民理念，更好运用“进一次门、查多项事”联合执法新方式，服务企业又快又好发展'
    },
    {
      name: '拓展智能便捷执法',
      content:
        '提供证照智能识别、信息语音转写、文书自动生成、类案推荐、预警监控等智能化辅助，支持在线沟通、手写签名、电子签章、电子送达、非税支付等'
    },
    {
      name: '灵活配置领域通适',
      content:
        '预制通用流程环节及文书，支持流程环节及文书个性化定制，满足各领域、各区域的行政执法办案需求，规范办案的同时，提升系统的灵活性'
    }
  ],
  cases: [
    {
      img: 'zhzf-img6.png',
      name: '云南省“区块链+行政执法和监督”平台',
      content:
        '云南省“区块链+行政执法和监督”平台涵盖线索管理、行政检查、行政处罚、行政强制、法制审核、辅助办案、案件移送、执法跟踪、移动执法等模块，支持两个领域、两个市州四级行政执法工作，执法数据实时上链、存证固证，实现执法活动严格规范公正文明，达到“审核一体化、办案智能化、取证便捷化、流程规范化”'
    },
    {
      img: 'zhzf-img9.png',
      name: '邢台市行政执法与行政执法监督一体化平台',
      content:'邢台市行政执法与行政执法监督一体化平台涵盖执法工作台、案源管理、办案管理、法制审核、统计分析、移动执法等模块，创新入企扫码功能，落实入企扫码制度，做到执法人员人人必知、入企必扫、执法结果互认、执法数据共享，实现行政执法过程“云备案、云监督”，强化数据支撑、科技赋能，为建设“太行泉城、美丽邢台”营造良好法治环境'
    },
    {
      img: 'enforce-case1.png',
      name: '广东省水政执法监督指挥平台',
      content:
        '广东省水政执法监督指挥平台涵盖水政执法工作台、执法综合业务管理、执法调度指挥、日常综合管理、数据可视化展示、执法监督移动端等功能模块，统一全省执法流程和执法标准，支持省市县三级行政执法和指挥调度，打造全省一体化、规范化、精细化、高效化的水政执法管理体系'
    },
    {
      img: 'enforce-case4.png',
      name: '长江水生生物保护管理与渔政执法远程监控指挥调度系统',
      content:
        '系统涵盖1个公共门户服务、6个业务系统（移动应用APP、指挥调度管理、渔政执法办案管理、水生生物保护动态管理、基础信息数据管理、综合数据管理），建设长江流域渔业水政统一办公系统，强化一体化要素管理，打造便捷高效渔政执法”'
    },
    
  ],
  moreCases: [
    {
      img: 'enforce-case3.png',
      name: '北京市行政执法信息服务平台',
      content:
        '北京市行政执法信息服务平台涵盖基础线索管理、检查案件管理、处罚案件管理、强制案件管理、重大法制审核、两法衔接、检查对象库管理、个性化流程定制、个性化文书定制、智能化识别当事人证件、智能语音识别录入、现场制作文书等，实现了执法规范化办理、功能智能化应用，多个方面积极探索创新，打造行政执法的“北京标准”'
    },
    { 
      img: 'zhzf-img1.png', 
      name: '北京市文化执法网络平台' 
    },
    {
      img: 'zhzf-img2.png',
      name: '北京市交通运输综合执法分析研判平台'
    },
    {
      img: 'zhzf-img3.png',
      name: '北京市民政局执法信息服务平台'
    },
     {
      img: 'enforce-case2.png',
      name: '深圳法治政府信息平台（行政执法系统）',
      content:
        '基于广东省、深圳市统一的执法流程标准、执法文书标准，构建统一的执法办案的系统，在此基础上，通过低代码平台按行业流程、文书个性定制，支撑市区两级执法部门及74个街道的全流程执法办案'
    },
    {
      img: 'zhzf-img4.png',
      name: '黑龙江省行政执法业务平台'
    },
    {
      img: 'zhzf-img5.png',
      name: '河北省行政执法一体化平台'
    },
    {
      img: 'zhzf-img8.png',
      name: '陕西省司法厅全省行政执法办案<br />信息化平台'
    },
    {
      img: 'zhzf-img7.png',
      name: '抚州市行政执法和行政执法监督平台'
    },
    {
      img: 'zhzf-img10.png',
      name: '阳泉市综合行政执法平台'
    },
    {
      img: 'zhzf-img11.png',
      name: '荆门智慧执法办案监管系统'
    },
    {
      img: 'zhzf-img12.png',
      name: '深圳光明智能执法系统'
    },
  ]
}
